import React from 'react';
import { BubbleChat } from 'flowise-embed-react';

// Wrap the root element to add the chat component globally
export const wrapRootElement = ({ element }) => {
  return (
    <>
      {element}
      <BubbleChat
        chatflowid="e4a3e678-c9c0-4033-b90c-920a0669b5e5"
        apiHost="https://riact-demo01-endpoint-gwbydzghcvhaa5db.z02.azurefd.net"
        theme={{
          chatWindow: {
            showTitle: true,
            showAgentMessages: true,
            title: 'RI Systems Bot',
            titleAvatarSrc: 'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
            welcomeMessage: 'Hello! My name is Lana, a customer support agent at RI Systems',
            errorMessage: 'Sorry I have to reach out to someone for that to answer',
            backgroundColor: '#ffffff',
            backgroundImage: 'enter image path or link',
            height: 700,
            width: 400,
            fontSize: 16,
            starterPrompts: [
                "Need help with my business process?",
                "What are your services?"
            ],
            starterPromptFontSize: 15,
            clearChatOnReload: false,
            sourceDocsTitle: 'Sources:',
            renderHTML: true,
            botMessage: {
                backgroundColor: '#f7f8ff',
                textColor: '#303235',
                showAvatar: true,
                avatarSrc: 'https://t4.ftcdn.net/jpg/06/73/30/57/360_F_673305789_1vVn75japKzhibzPIf6aKY1Gdm6sFhY1.jpg'
            },
            userMessage: {
                backgroundColor: '#3B81F6',
                textColor: '#ffffff',
                showAvatar: true,
                avatarSrc: 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png'
            },
            textInput: {
                placeholder: 'Type your question',
                backgroundColor: '#ffffff',
                textColor: '#303235',
                sendButtonColor: '#3B81F6',
                maxChars: 50,
                maxCharsWarningMessage: 'You exceeded the characters limit. Please input less than 50 characters.',
                autoFocus: true,
                sendMessageSound: true,
                sendSoundLocation: 'send_message.mp3',
                receiveMessageSound: true,
                receiveSoundLocation: 'receive_message.mp3'
            },
            feedback: {
                color: '#303235'
            },
            dateTimeToggle: {
                date: true,
                time: true
            },
            footer: {
                textColor: '#303235',
                text: 'Powered by',
                company: 'riACT',
                companyLink: 'https://risystems.com.au'
            }
        }
        }}
      />
    </>
  );
};