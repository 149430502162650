exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-hifreq-js": () => import("./../../../src/pages/hifreq.js" /* webpackChunkName: "component---src-pages-hifreq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-left-sidebar-js": () => import("./../../../src/pages/left-sidebar.js" /* webpackChunkName: "component---src-pages-left-sidebar-js" */),
  "component---src-pages-no-sidebar-js": () => import("./../../../src/pages/no-sidebar.js" /* webpackChunkName: "component---src-pages-no-sidebar-js" */),
  "component---src-pages-right-sidebar-js": () => import("./../../../src/pages/right-sidebar.js" /* webpackChunkName: "component---src-pages-right-sidebar-js" */),
  "component---src-pages-sentence-js": () => import("./../../../src/pages/sentence.js" /* webpackChunkName: "component---src-pages-sentence-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

